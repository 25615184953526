@import "../../shared/spacing.module";

.pageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.pageContainerLandscape {
  @extend .pageContainer;
  padding-top: $topNavHeightLandscape;
}

.pageContainerPortrait {
  @extend .pageContainer;
  padding-top: $topNavHeightPortrait;
}
