@import "../../shared/_colors.module.scss";

.textContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.highlightedText {
  font-family: "Rock Salt";
  font-weight: bold;
  color: $boldBlue;
  font-size: max(20px, 2vw);
  position: relative;
}

.textSegment {
  font-family: Roboto mono;
  color: $offWhite;
  font-size: max(15px, 1.8vw);
}

$textSpacing: 0.5em;
.spacingLeft {
  margin-left: $textSpacing;
}
.spacingRight {
  margin-right: $textSpacing;
}

.letsGoButton {
  color: $primaryYellow;
  font-size: max(20px, 1.8vw);
  font-family: Tomorrow;
  margin-top: 50px;

  border: none;
  background: none;
}
