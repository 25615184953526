.container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  overflow: hidden;
}

.rows {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100vw;
}

.columns {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 100vh;
}

.rowBorder {
  border-top: 1px dashed white;
  border-bottom: 1px dashed white;
  opacity: 0.2;
}

$columnCount: 40;
$columnWidth: 100vw / $columnCount;
.columnBorder {
  border-left: 1px dashed white;
  border-right: 1px dashed white;
  opacity: 0.2;
}
