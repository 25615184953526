@import "../../shared/_colors.module.scss";

.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textWrapper {
  width: max-content;
  height: max-content;
  position: relative;
  text-align: center;
  position: relative;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 2%;
}

.clickArea {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 3;
}

.titleFirstLetter {
  display: inline-block;
  color: $primaryYellow;
  position: relative;
  z-index: 2;
}

.titleRemainingLetters {
  display: inline-block;
  color: $offWhite;
  position: relative;
  z-index: 2;
}

.minorBorderContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50%;
  right: -50%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

$minorBorderOffset: -5px;

.minorBorderUpperLeft {
  border-left: 1px solid $primaryYellow;
  border-top: 1px solid $primaryYellow;
  position: absolute;
  height: 50%;
  aspect-ratio: 1;
  top: $minorBorderOffset;
  left: $minorBorderOffset;
}

.minorBorderLowerRight {
  border-right: 1px solid $primaryYellow;
  border-bottom: 1px solid $primaryYellow;
  position: absolute;
  height: 50%;
  aspect-ratio: 1;
  bottom: $minorBorderOffset;
  right: $minorBorderOffset;
}
