@mixin container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.containerLandscape {
  @include container;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px
}

.containerPortrait {
  @include container;
  justify-content: space-around;
  flex-direction: row;
}


.hobbyCardContainerLandscape {
  height: 150px;
  width: 150px
}
.hobbyCardContainerPortrait {
  width: 25%;
  aspect-ratio: 1;
}