@import "../../shared/_colors.module.scss";

.card {
  position: relative;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
}

@mixin front {
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $boldBlue;
  transform: rotateY(180deg);
  border-radius: 5px;
}

.frontLandscape {
  @include front;
  transform: rotateY(180deg);
}
.frontPortrait {
  @include front;
  transform: rotateX(180deg);
}

.back {
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  background: $boldBlue;
  border-radius: 5px;
}

.tagContainer {
  width: 100%;
  height: 100%;
}

.tag {
  position: relative;
  transform-style: preserve-3d;
  bottom: 50%;
}

.tagFront {
  background: $lightBlue;
  font-size: 22px;
  text-align: center;
  font-family: "Roboto Mono";
  width: 70%;
  backface-visibility: hidden;
  position: absolute;
  transform: rotateY(180deg);
}

.tagBack {
  background: $lightBlue;
  color: transparent;
  font-size: 22px;
  width: 70%;
  backface-visibility: hidden;
  position: absolute;
}
