@import "../../shared/colors.module.scss";

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  overflow: hidden;
  position: relative;
}

.mainSquare {
  background: $primaryBackground;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
}

.logoText {
  color: $boldBlue;
  font-size: 65px;
  font-family: "Tomorrow-extra-light", sans-serif;
}

.borderedSquare {
  position: absolute;
  border: 1px solid rgb(94, 94, 94);
  z-index: 2;
}

.fadedSquare {
  position: absolute;
  background: rgb(120, 208, 255);
  width: 100px;
  height: 100px;
  z-index: 1;
}
