@import "../shared/Icon/Icon.module.scss";
@import "../../shared/spacing.module";

@mixin navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
}

.navbarLandscape {
  @include navbar;
  height: $topNavHeightLandscape;
}

.navbarPortrait {
  @include navbar;
  height: $topNavHeightPortrait;
}

.homeLinkHidden {
  cursor: default
}

.homeButton {
  @include iconWrapper;
  display: flex;
  padding-left: 3vw;
  margin-bottom: 0;
}

.rightNavItems {
  display: flex;
}

.homeSVGVisible {
  opacity: 1;
  transition: all 0.5s;
}

.homeSVGHidden {
  opacity: 0;
  transition: all 0.5s;
}