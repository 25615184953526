@import "./shared/colors.module.scss";

@font-face {
  font-family: "Rock Salt";
  src: url(./fonts/RockSalt-Regular.ttf);
}
@font-face {
  font-family: "Tomorrow";
  src: url(./fonts/Tomorrow-Regular.ttf);
}
@font-face {
  font-family: "Tomorrow-extra-light";
  src: url(./fonts/Tomorrow-ExtraLight.ttf);
}
@font-face {
  font-family: "Roboto Mono";
  src: url(./fonts/RobotoMono-Regular.ttf);
}
@font-face {
  font-family: "Roboto Mono-bold";
  src: url(./fonts/RobotoMono-Bold.ttf);
}

.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: $primaryBackground;
}

* {
  margin: 0;
  padding: 0;
  scrollbar-width: none
}
