@import "../../shared/colors.module.scss";
@import "../../shared/spacing.module.scss";

@mixin container {
  background: $primaryBackground;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: calc(100vh - $topNavHeightLandscape);
}

.containerLandscape {
  margin-top: $topNavHeightLandscape;
  @include container;
}

.containerPortrait {
  margin-top: $topNavHeightPortrait;
  padding-top: $topNavHeightPortrait;
  @include container;
}

.lowerLeftBackground {
  background: #1f2635;
  position: absolute;
  left: -50%;
  width: 200vw;
  height: 100vh;
  transform-origin: top;
  z-index: 0;
}

.lowerRightBackground {
  background: #000000;
  position: absolute;
  left: -50%;
  width: 200vw;
  height: 100vh;
  transform-origin: top;
  z-index: 0;
}

.navigationLinksContainer {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  font-size: 2.5vw;
  font-family: "Roboto Mono";
  padding: 5px 40px 40px 40px;
  width: 50%;
  .containerPortrait & {
    font-size: 5vw;
    width: 90%;
  }
}

.mainContentContainer {
  z-index: 1;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: "center";
  position: relative;
  .containerPortrait & {
    flex-direction: column;
  }
}

.mainContentCenterColumn {
  flex: 1;
}
