$primaryBackground: #070d18;
$secondaryBackground: #11161f;

$offWhite: #e5e5e5;

$boldBlue: #00e0ff;
$boldBlueBackground: #032c31e0;
$lightBlue: #b6f2ff;
$greyBlue: #658492;

$primaryYellow: #fff500;
$primaryYellowBackground: #2d2b01e6;

$hotPink: #ff00dd;
$hotPinkBackground: #1c0118eb;

$gradient1: #00fffb;
$gradient2: #00c8ff;
$gradient3: #0084ff;
$gradient4: #0059ff;
$gradient5: #0026ff;

:export {
  primaryBackground: $primaryBackground;
  secondaryBackground: $secondaryBackground;
  offWhite: $offWhite;
  boldBlue: $boldBlue;
  lightBlue: $lightBlue;
  greyBlue: $greyBlue;
  primaryYellow: $primaryYellow;
  gradient1: $gradient1;
  gradient2: $gradient2;
  gradient3: $gradient3;
  gradient4: $gradient4;
  gradient5: $gradient5;
}
