@import "../../shared/colors.module.scss";

$spacing: 5px;

.container {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  margin: 20px 0;
  padding: $spacing;
  position: relative;
}

.title {
  font-family: "Tomorrow";
  color: $primaryBackground;
  font-size: 2em;
  text-align: left;
  width: 100%;
}

.subtext {
  font-family: "Roboto Mono";
  color: $primaryBackground;
  font-size: 1em;
  text-align: left;
  width: 100%;
}

.textSection {
  width: 100%;
  background: linear-gradient(90deg, $gradient2, $gradient3);
  position: relative;
  padding: 0 10px;
}
.textSectionTop {
  @extend .textSection;
}
.textSectionBottom {
  @extend .textSection;
}
.textSectionTop:after {
  content: '';
  position: absolute;
  top: 0; right: 0;
  border-top: 40px solid $primaryBackground;
  border-left: 40px solid transparent;
  width: 0;
}

.textSectionBottom:after {
  content: '';
  position: absolute;
  bottom: 0; left: 0;
  border-top: 40px solid transparent;
  border-left: 40px solid $primaryBackground;
  width: 0;
}


.pictureContainer {
  width: 100%;
  height: 0%;
  background: $primaryBackground;
  position: relative;
  background: $primaryBackground;
  margin: $spacing 0;
}

.picture {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.textAlignRight {
  text-align: right;
}

.topSpacing {
  margin-top: $spacing;
}
