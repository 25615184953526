.container {
  border: 3px solid white;
  background: black;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-decoration: none;
  position: relative;
  z-index: 2;
}

.textContainer {
  color: white;
  margin-left: 10px;
}

.upperText {
  font-size: 20px;
}

.lowerText {
  font-size: 35px;
}
