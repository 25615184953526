@import "../../shared/colors.module.scss";
@import "../../shared/breakpoints";

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}


@mixin splineContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  transition: all 1s;
  display: flex;
  justify-content: center;
  align-items: center;

  transform: scale(1);
  @media (max-width: 1024px) {
    transform: scale(0.5)
  }
  @media (max-width: 600px) {
    transform: scale(0.3)
  }
}

.splineContainerWaiting {
  @include splineContainer;
  opacity: 0;
}

.splineContainerStarted {
  @include splineContainer;
  opacity: 0.3;
  z-index: 1;
}

.midSection {
  width: max-content;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.mainHeading {
  display: inline-block;
  color: $boldBlue;
  font-family: "Tomorrow", sans-serif;
  font-weight: regular;
  font-size: 10vw;
  @media (max-width: $breakpointTablet) {
    font-size: 12vw;
  }
}

@mixin subheading {
  display: inline-block;
  font-family: "Tomorrow", sans-serif;
  font-weight: regular;
  font-size: 5vw;
  @media (max-width: $breakpointTablet) {
    font-size: 6vw;
    margin-top: 2vh;
  }
}

.subheading1 {
  @include subheading;
  color: $lightBlue;
  margin-left: 13vw;
}

.subheading2 {
  @include subheading;
  color: $greyBlue;
  margin-left: 26vw;
}

.footer {
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 300px;
  z-index: 2;
}

.iconsContainer {
  width: max-content;
  height: 100%;
  max-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-left: 3vw;
  position: absolute;
}

.footerLine {
  outline: 1px solid $offWhite;
}
