@import "../../shared/spacing.module";
@import "../../shared/colors.module";

.pageContainer {
  width: 100%;
  height: calc(100vh - $topNavHeightLandscape);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.pageContainerPortrait {
  @extend .pageContainer;
  height: calc(100vh - $topNavHeightPortrait);
  flex-direction: column;
  justify-content: space-around;
}

$containerWidth: 30vw;

.shapeContainer {
  width: $containerWidth;
  height: calc($containerWidth / 1.35);
  position: relative;
}

.landscapeLeftContainer {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landscapeRightContainer {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 30px;
  padding-right: 30px;
}

$imageHeight: 80vh;
.image {
  height: $imageHeight;
}
.appImageContainer {
  height: $imageHeight;
  position: relative;
  margin: 0 30px;
}
$imageHeightPortrait: 50vh;
.imagePortrait {
  height: $imageHeightPortrait;
}
.appImageContainerPortrait {
  height: $imageHeightPortrait;
  position: relative;
  margin: 0 30px;
}

.text {
  color: $lightBlue;
  font-family: "Roboto Mono-bold";
  text-align: center;
  font-size: 2em;
}
.textPortrait {
  @extend .text;
  font-size: 1.5em;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.title {
  color: $boldBlue;
  font-family: "Tomorrow";
  font-size: 7em;
  text-align: center;
  padding: 0 10px;
}
.titlePortrait {
  @extend .title;
  font-size: 2em;
}
.titleContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.svgContainer {
  position: relative;
}
