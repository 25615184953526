@import "../../shared/_colors.module.scss";
@import "../../shared/_breakpoints";

.listItem {
  display: flex;
  flex-direction: column;
  font-size: 2vw;
  list-style-type: none;
  color: $offWhite;
  margin-right: 3vw;
  font-family: "Roboto Mono";
  cursor: pointer;
  height: max-content;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpointTablet) {
    font-size: 5vw;
    margin-right: 4vw;
  }
}

$indicatorWidth: 1px;
$transition: all 0.5s;

.activeIndicator {
  width: 100%;
  border: $indicatorWidth solid $primaryYellow;
  transition: $transition
}

.inactiveIndicator {
  width: 0%;
  border: $indicatorWidth solid transparent;
  transition:  $transition
}

.textContainer {
  position: relative;
  display: flex;
}

.clickArea {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.itemText {
  color: $offWhite;
}

.itemTextBoldHighlighted {
  color: $primaryYellow;
  font-family: "Roboto Mono-bold";
}

.itemTextHighlighted {
  color: $primaryYellow;
}