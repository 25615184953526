@import "../../shared/spacing.module.scss";

.pageContainer {
  width: 100%;
  height: calc(100vh - $topNavHeightLandscape);
  margin-top: $topNavHeightLandscape;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.splineContainer {
  opacity: 0;
  position: absolute;
  height: 100%;
  right: 50px;
  top: 0;
  transform: translate(-50%, -50%);
  transition: all 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  transform: scale(1);
  @media (max-width: 1024px) {
    transform: scale(0.5)
  }
  @media (max-width: 600px) {
    transform: scale(0.3)
  };
}