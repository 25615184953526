@import "../../../shared/colors.module.scss";
@import "../../../shared/breakpoints";

@mixin iconWrapper {
  color: $primaryYellow;
  margin-bottom: 30px;

  svg {
    font-size: 2.5vw;
    @media (max-width: $breakpointTablet) {
      font-size: 10vw;
    }
  }
}

.iconWrapper {
  @include iconWrapper
}